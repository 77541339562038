<template>
  <navigation index="repairs" ref="navigations">
    <div class="topnavigation">
      <div style="display: flex; gap: 10px; align-items: center; height: 40px">
        <el-button type="primary" @click="CreateRepair" size="small">
          {{ tools.GetLanguageValue("web.新增维修") }}</el-button
        >

        <el-input
          size="small" clearable
          v-model="repairListPadding.Search"
          :placeholder="tools.GetLanguageValue('web.请输入')"
          @input="handleQuery"
          style="width: 150px"
          :prefix-icon="Search"
        ></el-input>

        <el-input
          v-if="barcode != null && barcode != undefined && barcode.length > 0"
          size="small"
          clearable
          v-model="barcode"
          style="width: 150px"
        >
          <template #prefix>
            <el-icon>
              <svg
                t="1721663119793"
                class="icon"
                viewBox="0 0 1408 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4261"
                width="200"
                height="200"
              >
                <path
                  d="M447.964319 1024H608.957443V0h-160.993124z m-127.881063 0h63.940531V0h-63.940531z m351.863222 0h64.130831V0h-64.130831z m-543.875116 0h127.881063V0H128.071362zM0 63.940531v896.118938a64.130831 64.130831 0 0 0 63.940531 63.940531V0A64.130831 64.130831 0 0 0 0 63.940531zM1344.083256 0h-64.130831v1024h64.130831A64.130831 64.130831 0 0 0 1408.214087 960.059469V63.940531A64.130831 64.130831 0 0 0 1344.083256 0zM799.256644 1024h64.701728V0H799.256644z m352.053521 0h64.701729V0h-63.940532z m-224.172458 0h160.041628V0h-159.280431z"
                  p-id="4262"
                ></path>
              </svg>
            </el-icon>
          </template>
        </el-input>

        <el-checkbox-group
          v-model="repairListPadding.Where.Repairstatus"
          @change="ChangeRepairstatus"
          text-color="#fff"
        >
          <el-badge
            :value="item.Count"
            class="repairstatus_item"
            v-for="(item, index) in RepairSatusCount"
            :key="index"
            style="color: #fff"
          >
            <el-checkbox
              size="small"
              style="background-color: #fff"
              :label="item.Repairstatus"
              border
            >
              {{ item.Name }}
            </el-checkbox>
          </el-badge>
        </el-checkbox-group>
  
        <el-select
          :placeholder="tools.GetLanguageValue('web.请选择维修单状态')"
          collapse-tags="3"
          style="width: 130px"
          size="small"
          popper-class="eloption"
          :popper-append-to-body="true"
          multiple
          @change="ChangeRepairstatus"
          v-model="repairListPadding.Where.Repairstatus"
        >
          <el-option
            v-for="item in repairstatusOptions"
            :key="item.Value"
            :label="item.Value"
            :value="item.Key"
          >
            {{ item.Value }}
          </el-option>
        </el-select>

        <el-date-picker
          v-if="ShowWhere"
          value-format="YYYY/MM/DD HH:mm:ss"
          style="width: 160px"
          size="small"
          @change="ChangeDateSearch"
          v-model="SearchDate"
          type="datetimerange"
          range-separator="To"
        />
        <el-button @click="ShowWhere = !ShowWhere" size="small">
          {{ tools.GetLanguageValue("web.筛选") }}
        </el-button>

        <!-- 
        <el-select
          :placeholder="tools.GetLanguageValue('web.请选择维修单状态')"
          collapse-tags="3"
          style="width: 180px"
          size="small"
          multiple
          v-model="repairListPadding.Where.Repairstatus"
        >
          <el-option
            v-for="item in repairstatusOptions"
            :key="item.Value"
            :label="item.Value"
            :value="item.Key"
          >
            {{ item.Value }}
          </el-option>
        </el-select> -->
      </div>
    </div>

    <div style="width: calc(100% - 10px)">
      <el-table
        class="dataTable custom-title-font"
        border
        :row-class-name="tableRowClassName"
        :data="repairList"
        style="width: calc(100%)"
        @cell-click="handleCellClick"
        row-key="Id"
        :height="ContentHeight + 'px'"
        default-expand-all
        :tree-props="{
          children: 'RepairMobileInfos',
          hasChildren: 'hasChildren',
        }"
      >
        <el-table-column
          prop="RepairNo"
          width="140"
          class-name="padding0item"
          :label="tools.GetLanguageValue('web.单号')"
        >
          <template #default="{ row }">
            <span v-if="row.IsReworking" style="color: red">
              {{ row.RepairNo }}
            </span>
            <span v-if="!row.IsReworking">
              {{ row.RepairNo }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          class-name="padding0item"
          prop="ClientName"
          width="140"
          :label="tools.GetLanguageValue('web.客户姓名')"
        >
          <template #default="{ row }">
            <span>{{ row.ClientName }} </span>
          </template>
        </el-table-column>
        <el-table-column
          class-name="padding0item"
          prop="ClientName"
          width="110"
          :label="tools.GetLanguageValue('web.手机')"
        >
          <template #default="{ row }">
            <span>{{ row.PhoneNumber }} </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="BrandAndModel"
          class-name="padding0item"
          :label="tools.GetLanguageValue('web.维修品牌')"
          width="180"
        >
          <template #default="{ row }">
            <el-tag
              style="margin: 3px"
              v-if="row.BrandAndModel != null && row.BrandAndModel.length > 0"
              type="info"
              >{{ row.BrandAndModel }}</el-tag
            >
            <el-tag
              style="margin: 3px"
              v-if="row.ModelName != null && row.ModelName.length > 0"
              >{{ row.ModelName }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          class-name="padding0item"
          prop="RepairProblems"
          :label="tools.GetLanguageValue('web.维修问题/服务')"
          width="170"
        >
          <template #default="{ row }">
            <el-tag
              size="small"
              v-show="index < 3"
              style="margin: 3px"
              v-for="(item, index) in row.RepairProblems"
              :key="item"
            >
              {{ item }}
            </el-tag>
            <span
              v-if="
                row.RepairProblems != null && row.RepairProblems.length >= 3
              "
            >
              ...
            </span>
          </template>
        </el-table-column>
      
        <el-table-column
          prop="RepairStartTimeFormat"
          class-name="padding0item"
          :label="tools.GetLanguageValue('web.维修时间')"
          width="160"
        ></el-table-column>
        <el-table-column
          class-name="padding0item"
          prop="WarrantyStartTimeFormat"
          :label="tools.GetLanguageValue('web.保修开始日期')"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="RepairOperatorName"
          class-name="padding0item"
          :label="tools.GetLanguageValue('web.创建人')"
          width="150"
        >
          <template #default="{ row }">
            <p
              style="
                width: 120px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
              "
            >
              {{ row.RepairOperatorName }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          :label="tools.GetLanguageValue('web.状态')"
          fixed="right"
          class-name="padding0item"
          width="190"
        >
          <template #default="{ row }">
            <div style="display: flex; gap: 5px" v-if="row.Tree != 'YES'">
         
              <el-select
                size="small"
                v-model="row.Repairstatus"
                @change="changeStatus(row)"
                style="width: 120px"
              >
                <el-option
                  v-for="item in repairstatusOptions"
                  :key="item.Value"
                  :label="item.Value"
                  :value="item.Key"
                >
                  {{ item.Value }}
                </el-option>
              </el-select>

              <el-button  v-if="row.Repairstatus  != 600" 
                type="primary"
                size="small"
                @click="CutRepairstatus(row)"
              >
                <el-icon>
                  <refresh /> 
                </el-icon>
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center; height: 50px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="repairListPadding.Page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="repairListPadding.Size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="repairListPadding.Count"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      v-if="CreateRepairWindow"
      v-model="CreateRepairWindow"
      :title="tools.GetLanguageValue('web.创建维修单')"
      width="800px"
      :draggable="true"
      :show-close="false"
    >
      <div style="min-height: 300px">
        <RepairClient></RepairClient>
      </div>
    </el-dialog>
  </navigation>
</template>
<script setup>
import { useRouter, useRoute } from "vue-router";
import axios from "../../../commons/AxiosMethod.js";
import navigation from "../../../components/Modules/Navigation.vue";
import RepairClient from "../Repairs/RepairClient.vue";
import {
  Search,
  Close,
  Refresh,
  Message,
  IconBarCode,
} from "@element-plus/icons-vue";
import { onBeforeMount, onMounted, onUnmounted, ref } from "vue";
import tools from "../../../commons/tools.js";
import { ElMessage, ElMessageBox } from "element-plus";

const clientWidth = document.body.clientWidth;
const clientHeight = document.documentElement.clientHeight;

const queryWord = ref("");
const route = useRoute();
const router = useRouter();
const IsReworking = ref(true);
const ShowWhere = ref(false);

const selectRepairStatusValue = ref();
const queryAll = ref("all");
const needClear = ref(false);
const repairstatusOptions = ref([]); // 维修状态数据
const repairReserveNum = ref(0); // 预定数量
const repairPendingNum = ref(0); // 待开始数量
const repairRepairingNum = ref(0); // 维修中数量

// const options = reactive(optionsMap.value)
const repairData = ref([]);
const navigations = ref();
const ContentHeight = ref(document.documentElement.clientHeight - 130);

const tableRowClassName = ({ row, rowIndex }) => {
  if (row.Tree == "YES") {
    return "success-row";
  } else if (row.Tree == "single") {
    return "warning-row";
  }
  return "";
};

// 监听页面大小变化事件
const getWindowInfo = () => {
  const windowInfo = {
    width: window.innerWidth,
    hight: window.innerHeight,
  };
  navigations.value.onresize();
  ContentHeight.value = windowInfo.hight - 130;
};
window.addEventListener("resize", getWindowInfo);

//--------------------扫码搜索功能----------------------

const barcode = ref("");

const handleKeypress = (event) => {
  // 假设扫码枪输入以回车键结束
  if (event.key === "Enter") {
    // 处理扫描到的条形码
    console.log("扫描到的条形码:", barcode.value);
    // 重置条形码以便下一次扫描

    repairListPadding.value.Search = barcode.value;
    handleQuery();
  barcode.value = "";
  } else {
    // 累积扫码枪输入的字符
    barcode.value += event.key;
  }
};

// 页面加载
onMounted(() => {
  //检查连接是否带有筛选条件

  try {
    var status = route.query.status;
    if (status != "" && status != null && status != undefined) {
      repairListPadding.value.Where.Repairstatus.push(Number(status));
    }
  } catch (e) {}

  getRepairList();
  navigations.value.onresize();

  document.addEventListener("keypress", handleKeypress);
});

onUnmounted(() => {
  // 组件卸载时移除全局键盘事件监听器
  document.removeEventListener("keypress", handleKeypress);
});

//----------------------重构------------------------

const CreateRepairWindow = ref(false);

// 创建单据
const CreateRepair = () => {
  //弹出一个框
  CreateRepairWindow.value = true;

  // router.push({ name: "repairClient", query: { id: guid() } });
};

const SearchDate = ref([]); // 维修中数量

//设置取走
const ChangeDateSearch = (item) => {
 
  if (SearchDate.value == null) {
    repairListPadding.value.Where.StartTime = null;
    repairListPadding.value.Where.EndTime = null;
  } else {
    repairListPadding.value.Where.StartTime = SearchDate.value[0];
    repairListPadding.value.Where.EndTime = SearchDate.value[1];
  }

  getRepairList();
};

//切换到下一状态
const CutRepairstatus = (item) => {
  axios.apiMethod("/stores/Repair/CutRepairstatus", "post", item, (result) => {
    if (result.Data.IsSuccess) {
      getRepairList();
      getRepairSatusCount();
    } else {
      ElMessage({
        message: result.Data.Msg,
        type: "error",
      });
    }
  });
};

const RepairSatusCount = ref({});

// 获取三个状态的统计数量
const getRepairSatusCount = () => {
  axios.apiMethod(
    "/stores/Repair/GetRepairSatusCount",
    "get",
    null,
    (result) => {
      RepairSatusCount.value = result.Data;
    }
  );
};

// 选择改变
const changeStatus = (row) => {
  // 改变之后调用更新方法
  axios.apiMethod(
    "/stores/Repair/UpdateRepairMobileStatus",
    "post",
    row,
    (result) => {
      if (result.Data.IsSuccess) {
        ElMessage({
          message: tools.GetLanguageValue("web.操作成功"),
          type: "success",
        });
        getRepairSatusCount();
      } else {
        ElMessage({
          message: result.Data.Msg,
          type: "error",
        });
      }
      getRepairList();
    }
  );
};

const CheckReworking = () => {
  repairListPadding.value.Where.IsReworking = IsReworking.value;
  getRepairList();
};

const ChangeRepairstatus = () => {
  getRepairList();
};

const repairListPadding = ref({
  Where: {
    Repairstatus: [],
    IsReworking: IsReworking.value,
  },
  Search: "",
  Page: 1,
  Size: 30,
});

const repairList = ref([]);

// 获取维修单列表
const getRepairList = () => {
  axios.apiMethod(
    "/stores/Repair/GetRepairList",
    "post",
    repairListPadding.value,
    (result) => {
      repairList.value = result.Data.Datas;
      repairListPadding.value.Page = result.Data.Page;
      repairListPadding.value.Size = result.Data.Size;
      repairListPadding.value.Count = result.Data.Count;
    }
  );
};

const S4 = () => {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};
const guid = () => {
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

// 行点击跳转到详情页
const handleCellClick = (row, column, cell, event) => {
  if (cell.cellIndex > 7) return;
  // 处理行数据，然后跳转到repairDetail页面
  if (row.Tree == "YES") {
    return;
  }

  router.push({
    name: "repairDetail",
    query: { id: row.RepairId, itemId: row.Id },
  });
};

// 页面加载前
onBeforeMount(() => {
  getRepairstatusEnum();
  getRepairSatusCount();
});

// 状态下拉列表初始化
const getRepairstatusEnum = () => {
  axios.apiMethod(
    "/sys/SystemData/GetRepairstatusEunm",
    "get",
    null,
    (result) => {
      console.log(result.Data);
      repairstatusOptions.value = result.Data;
    }
  );
};

const handleSizeChange = (val) => {
  repairListPadding.value.Size = val;
  repairListPadding.value.Page = 1;
  getRepairList();
};
const handleCurrentChange = (val) => {
  repairListPadding.value.Page = val;
  getRepairList();
};

const handleQuery = () => {
  repairListPadding.Page = 1;
  getRepairList();
};
</script>
<style>
.dataTable .el-table__row {
  cursor: pointer;
}
.repairstatus_item {
  margin: 5px;
}
</style>
<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
}
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
.padding0item {
  padding: 0px !important;
  height: 30px;
}
</style>